.resume-transactions-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  grid-gap: 10px;
}

.resume-transactions-tittle {
  margin: -10px 0;
  grid-column: 1 / span 2;
  grid-row: 1;
  display: flex;
  justify-content: space-between;
}

.resume-transactions-left {
  grid-column: 1;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  margin-left: 30%;
}

.resume-transactions-right {
  grid-column: 2;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  margin-left: 30%;
}

.resume-transactions-container > button {
  grid-column: 1 / span 2;
  margin: 8px auto;
}

.resume-transactions-tittle h3:first-child {
  margin-right: auto;
}

.resume-transactions-tittle h3:last-child {
  margin-left: auto;
}

.resume-transactions-container div input {
  width: 25%;
}

@media (max-width: 600px) {
  .resume-transactions-container h3{
    font-size: 18px;
  }

  .resume-transactions-container span{
    font-size: 16px;
  }

  .resume-transactions-left {
    margin-left: 3%;
  }
  
  .resume-transactions-right {
    margin-left: 6%;
  }
}
