.totals-monthly-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.totals-monthly-container > hr {
  width: 40vw;
  margin: 0;
  align-self: center;
}

.totals-monthly-container > div {
  display: flex;
  margin-bottom: 8px;
  align-items: baseline;
  justify-content: space-between;
}

.totals-monthly-container > div > h4 {
  margin: 0 7px;
  flex: 2 1;
}

.totals-monthly-container .total-categories {
  display: flex;
  flex: 8 1;
  justify-content: space-between;
}

.totals-monthly-container .info-category{
    display: flex;
}

.totals-monthly-container .info-category .resume-category{
  font-size: 13px;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.hidden-info {
  display: none;
}

.hidden-info > p {
  font-size: 12px;
  margin: 0;
}

.totals-monthly-container .info-category:hover .hidden-info,
.totals-monthly-container .info-category:active .hidden-info{
  display: inline-block;
  position: fixed;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: #282d47db;
  border: 2px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 36px 8px;
  color: #f0f8ff;
}

@media (max-width: 600px) {
  .totals-monthly-container > div {
    display: flex;
    margin-bottom: 8px;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .totals-monthly-container > div > div {
    width: 100%;
  }
  
  .totals-monthly-container > div > h4 {
    margin: 7px;
    margin-bottom: 0;
    font-size: 15px;
  }
  
  .totals-monthly-container .info-category .resume-category{
    font-size: 10px;
  }

  .totals-monthly-container > hr {
    width: 80vw;
    margin: 0;
    align-self: center;
  }
}
