body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: aliceblue;
  background-color: #303c57;
}

#root {
  display: flex;
  font-weight: normal;
  margin-top: 15px;
  flex-direction: column;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.component-default-container {
  display: flex;
  width: 45vw;
  border-radius: 5px;
  padding: 7px 15px;
  margin-bottom: 10px;
  justify-content: space-between;
}

@media (max-width: 1024px) {
  .component-default-container {
    width: 65vw;
  }
}

@media (max-width: 600px) {
  .component-default-container {
    width: 88vw;
  }
}

.outflow-container {
  background-color: #482929;
}
.outflow-table {
  background-color: #7d3939;
}
.outflow-table tbody tr:nth-child(even) {
  background: #593636;
}
.outflow-table tbody tr:nth-child(odd) {
  background: #482929;
}

.inflow-container {
  background-color: #292e48;
}
.inflow-table {
  background-color: #34497a;
}
.inflow-table tbody tr:nth-child(even) {
  background-color: #253557;
}
.inflow-table tbody tr:nth-child(odd) {
  background-color: #292e48;
}


