.table-container {
  display: flex;
  width: 70vw;
  font-size: 16px;
  overflow: auto;
}

table {
  border: none;
  border-collapse: collapse;
  border-radius: 5px;
  width: 100%;
}

table td, table th {
  padding: 3px 10px;
  word-wrap: break-word;
}

.transcationRow button{
  border: none;
  background: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.edit-transaction {
  width: 12vw;
  height: 4vh;
  font-size: 14px;
}

.edit-transaction[type=number] {
  width: 4vw;
}

.actions-row {
  display: flex;
  justify-content: center;
  gap: 15px;
}

@media (max-width: 1024px) {
  .table-container {
    width: 80vw;
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .table-container {
    width: 95vw;
    font-size: 12px;
  }

  table td, table th {
    padding: 3px 5px;
    word-wrap: break-word;
  }

  .edit-transaction {
    width: 40vw;
    height: 3vh;
  }

  .edit-transaction[type=number] {
    width: 15vw;
  }

  .edit-transaction[type=text] {
    font-size: 12px;
  }
}
