.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
  height: 90vh;
  justify-content: space-between;
}

.login-page img {
  width: 13%;
}

.login-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  background-color: #292e48;
  margin: 10px 0;
  padding: 15px 15px;
  box-shadow: 6px 5px 20px 4px rgb(16 16 37 / 27%);
}

.login-container h2 {
  margin: 0;
}


.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  height: 25vh;
  justify-content: space-around;
}

.login-form label {
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  font-size: 18px;
  align-items: flex-end;
}

.login-form input {
  flex: 1;
  font-size: 14px;
  margin-left: 10px;
}

.login-form button {
  padding: 5px 10px;
}

@media (max-width: 600px) {
  .login-page {
    width: 80%;
    height: 70vh;
  }

  .login-page img {
    width: 30%;
  }

  .login-container h2 {
    margin: 10px;
  }

  .login-form {
    margin-top: 0;
    height: 18vh;
  }

  .login-form label {
    font-size: 16px;
  }
  
  .login-form input {
    font-size: 12px;
  }
}
