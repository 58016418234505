.change-page-container {
  border-radius: 5px 5px 0 0;
  margin-bottom: 0;
}

.change-page-container > button {
  all: initial;
  color: inherit;
  font-family: inherit;
  margin: -10px 0;
}

.change-page-container > button:hover {
  cursor: pointer;
}

.change-page-container .inflow-selected {
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: inset 0px 0px 11px 2px #202331;
}

.change-page-container .inflow-not-selected {
  padding: 5px 10px;
  color: #788093;
  border-radius: 5px;
  box-shadow: inset 0px 0px 11px 2px #312020;
}

.change-page-container .outflow-selected {
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: inset 0px 0px 11px 2px #312020;
}

.change-page-container .outflow-not-selected {
  padding: 5px 10px;
  color: #788093;
  border-radius: 5px;
  box-shadow: inset 0px 0px 11px 2px #202331;
}

@media (max-width: 600px) {
  .change-page-container {
    font-size: 15px;
    padding: 0px 15px;
  }
}
