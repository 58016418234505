.user-profile-container h2 {
  margin: 8px 0;
}

.user-profile-container button {
  width: 15%;
  border-radius: 5px;
}

.change-pass-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 60%;
}

.change-pass-label-container {
  display: flex;
  flex-direction: column;
}

.change-pass-label-container label {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.change-pass-label-container input {
  font-size: 12px;
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 10px;
}

/* table {
  width: 100%;
  max-width: 100%;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
}

table td, table th {
  padding: 3px 10px;
  word-wrap: break-word;
}

table tbody tr:nth-child(even) {
  background: #253557;
}

table tbody tr:nth-child(odd) {
  background: #292e48;
}
*/

@media (max-width: 600px) {
  .user-profile-container {
    width: 88%;
    font-size: 12px;
  }

  .user-profile-container button {
    width: 25%;
    font-size: 11px;
  }

  .change-pass-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 60%;
    justify-content: space-around;
  }

  .change-pass-label-container input {
    font-size: 9px;
    display: flex;
    align-items: center;
    flex: 0;
    margin-left: 0px;
  }

  .change-pass-label-container label {
    font-size: 11px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .change-pass-form button {
    height: 40%;
  }
}
