.transaction-form {
  flex-direction: column;
  border-radius: 0 0 5px 5px;
}

.transaction-form > h2 {
  align-self: flex-start;
  margin: 5px 0px;
}

.transaction-form > label, .transaction-form > div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-evenly;
}

.transaction-form > label > input,
.transaction-form > label > select {
  margin-left: 15px;
  flex: 1;
}

button {
  width: 50%;
  align-self: center;
}

.transaction-form > div > label {
  display: flex;
  align-items: center;
}

#autoComplete {
  display: grid;
  grid-template-columns: auto 1fr;
  position: relative;
  width: 100%;
}

#autoComplete > section {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: #222743f5;
  padding: 5px 0;
  border-radius: 5px;
}

#TransctionCodeSuggestions {
  background-color: red;
  font-size: 14px;
  z-index: 99;
}

#autoComplete > section {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: #222743f5;
  padding: 5px 0;
  border-radius: 5px;
}

#autoComplete > section > button {
  width: 90%;
  margin: 1px 0;
}

.select_outflow_transaction_code {
  width: 80%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin-left: 15px;
  grid-row-gap: 10px;
  position: relative;
}
.suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #482929;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 100%;
}

.suggestions-list li {
  padding: 4px 10px;
  cursor: pointer;
  font-size: 14px;
}

.suggestions-list li:hover {
  background-color: #420f0f;
}
@media (max-width: 600px) {
  .transaction-form>label>input, .transaction-form>label>select {
    font-size: 14px;
  }

  .transaction-form span {
    font-size: 18px;
  }

  .transaction-form>div, .transaction-form>label {
    margin-bottom: 15px;
  }

  input[type='radio'] { 
    transform: scale(1.2); 
  }

  button {
    align-self: center;
    width: 50%;
    height: 1.5rem;
    margin: 10px 0;
  }
}
